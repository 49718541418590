/* Productos de Bondage */
export const bondageProds = [
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699462687/Imagen_1_Arnes_de_Gl%C3%BAteos_con_Esposas_xrqsal.jpg',
        ],
        colors: ['Negro',],
        description: 'Con un ajuste perfecto y materiales suaves, te sentirás cómodo mientras despiertas tu lado más juguetón.',
        price: '15.00',
        title: 'Arnés de Glúteos con Esposas',
    },
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699462827/imagen_1_Kit_BDSM_Set_ptvzad.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699462827/imagen_2_Kit_BDSM_Set_s6wo2n.jpg',
        ],
        colors: ['Negro', 'Rojo',],
        description: '¡Da vida a tus celebraciones con nuestro exclusivo Kit para Amarrar Hallacas! Todo lo que necesitas para hacer las mejores hallacas está en este conjunto especial que harán más interesantes estas fiestas.',
        price: '15.00',
        title: 'Kit para Amarrar Hallacas',
    },
]

/* Productos de Medias */
export const mediasProds = [
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463163/Imagen_1_Medias_Pantys_Pieza_tq1cxt.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463163/Imagen_2_Medias_Pantys_Pieza_kz8vgz.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463163/Imagen_3_Medias_Pantys_Pieza_iu9bgd.jpg',
        ],
        colors: ['Negro',],
        description: 'Ya sea para una ocasión especial o para uso diario, nuestras Medias Pantys son la elección perfecta para lucir impecable en cualquier momento.',
        price: '3.50',
        title: 'Medias Pantys',
    },
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463240/Imagen_1_Medias_Tobilleras_de_Malla_Par_zb9xia.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463235/Imagen_2_Medias_Tobilleras_de_Malla_Par_iawwgx.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463236/Imagen_3_Medias_Tobilleras_de_Malla_Par_dqyyc8.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463238/Imagen_4_Medias_Tobilleras_de_Malla_Par_wmtlsq.jpg',
        ],
        colors: ['Negro',],
        description: 'Las Medias Tobilleras de Malla son una opción versátil para complementar cualquier outfit.',
        price: '1.50',
        title: 'Medias Tobilleras de Malla',
    },
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463325/Imagen_1_Medias_Tobilleras_Transparentes_Par_cfxfyd.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463323/Imagen_2_Medias_Tobilleras_Transparentes_Par_kg71ck.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463324/Imagen_3_Medias_Tobilleras_Transparentes_Par_lzvzn4.jpg',
        ],
        colors: ['Negro',],
        description: 'Nuestras medias tobilleras transparentes de nylon son una prenda versátil y cómoda que se adapta a cualquier estilo',
        price: '1.00',
        title: 'Medias Tobilleras Trnasparentes',
    },
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463376/Imagen_1_Medias_Transparentes_Nylon_Par_cfnmf6.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463377/Imagen_2_Medias_Transparentes_Nylon_Par_vewhbc.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463378/Imagen_3_Medias_Transparentes_Nylon_Par_zfp2gt.jpg',
        ],
        colors: ['Negro', 'Blanco'],
        description: 'Ya sea para una ocasión especial o simplemente para sorprender a tu pareja, nuestras Medias Transparentes de Nylon serán tu arma secreta.',
        price: '2.50',
        title: 'Knee Socks',
    },
]

/* Productos de Ropa */
export const ropaProds = [
    {
        collageImages: [
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463532/Imagen_1_Pantalones_de_Cuerina_Pieza_xgqgk3.jpg',
            'https://res.cloudinary.com/djosi3wtf/image/upload/v1699463533/Imagen_2_Pantalones_de_Cuerina_Pieza_ewpe7e.jpg',
        ],
        colors: ['Negro',],
        description: '¿Buscas un look atrevido y sofisticado? Prueba nuestros pantalones de cuerina, ideales para combinar con cualquier prenda.',
        price: '20.00',
        title: 'Pantalones de Cuerina',
    },
]

export const allProds = [ ...bondageProds, ...mediasProds, ...ropaProds ]
